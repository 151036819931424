// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { errorAlert, successAlert } from '../../../../utility/alert'

export const getAllData = createAsyncThunk('reservations/getAllData', async () => {
  const response = await axios.get('/api/reservations/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('reservations/getData', async params => {
  const response = await axios.get(
    'https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/list?with_paginate=yes',
    { params }
  )
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: true
  }
})

export const getKids = createAsyncThunk('reservations/getKids', async params => {
  const response = await axios.get(
    'https://joycitybackend.bw-businessworld.com/api/v1/web/kids/list?with_paginate=no',
    params
  )
  return {
    kids: response.data.data
  }
})
export const getUser = createAsyncThunk('reservations/getUser', async id => {
  const response = await axios.get('/api/reservations/user', { id })
  return response.data.user
})
export const addReservation = createAsyncThunk('reservations/addReservation', async (user, { dispatch, getState }) => {
  await axios
    .post('https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/store', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })

  await dispatch(getData(getState().reservations.params))
  return user
})

export const deleteUser = createAsyncThunk('reservations/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(`https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/delete/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
  await dispatch(getData(getState().reservations.params))
  return id
})

export const updateUser = createAsyncThunk('reservations/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(`https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/update/${params.id}`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
  await dispatch(getData(getState().reservations.params))
  return params.id
})
export const startReservation = createAsyncThunk(
  'reservations/startReservation',
  async (id, { dispatch, getState }) => {
    await axios
      .post(`https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/open/${id}`, { _method: 'put' })
      .then(res => {
        successAlert()
      })
      .catch(err => {
        errorAlert(err.response.data.messages[0])
      })
    await dispatch(getData(getState().reservations.params))
    return id
  }
)
export const endReservation = createAsyncThunk('reservations/endReservation', async (id, { dispatch, getState }) => {
  await axios
    .post(`https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/close/${id}`, { _method: 'put' })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
  await dispatch(getData(getState().reservations.params))
  return id
})
export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    parents: [],
    loading: false,
    kids: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getKids.fulfilled, (state, action) => {
        state.kids = action.payload.kids
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default reservationsSlice.reducer
