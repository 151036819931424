import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const successAlert = () => {
  return MySwal.fire({
    title: "عمل جيد!",
    text: "تم اكمال العمل بنجاح!",
    icon: "success",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
};

export const warningAlert = () => {
  return MySwal.fire({
    title: "Warning!",
    text: " You clicked the button!",
    icon: "warning",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
};

export const errorAlert = (message) => {
  return MySwal.fire({
    title: "خطأ..",
    text: message || "حدث خطأ ما",
    icon: "error",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
};
export const confirmAlert = (id, Action) => {
  return MySwal.fire({
    title: "هل أنت متأكد؟",
    text: "لا تستطيع التراجع!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "نعم!",
    cancelButtonText: "تراجع",
    customClass: {
      confirmButton: "btn bt-main btn-primary",
      cancelButton: "btn btn-danger ms-1",
    },
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      Action(id);
    }
  });
};
