// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { errorAlert, successAlert } from '../../../../utility/alert'

export const getAllData = createAsyncThunk('kids/getAllData', async () => {
  const response = await axios.get('/api/kids/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('kids/getData', async params => {
  const response = await axios.get(
    'https://joycitybackend.bw-businessworld.com/api/v1/web/kids/list?with_paginate=yes&sort_by=id&sort=asc',
    { params }
  )
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})
export const getParents = createAsyncThunk('kids/getParents', async params => {
  const response = await axios.get(
    'https://joycitybackend.bw-businessworld.com/api/admin/Users?with_paginate=no',
    params
  )
  return {
    parents: response.data.data
  }
})
export const getKids = createAsyncThunk('kids/getKids', async params => {
  const response = await axios.get('https://joycitybackend.bw-businessworld.com/api/users?with_paginate=no', params)
  return {
    kids: response.data.data
  }
})
export const getUser = createAsyncThunk('kids/getUser', async id => {
  const response = await axios.get('/api/kids/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('kids/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/kids/add-user', user)
  await dispatch(getData(getState().kids.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('kids/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(`https://joycitybackend.bw-businessworld.com/api/v1/web/kids/delete/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err?.response?.data?.messages[0])
    })
  await dispatch(getData(getState().kids.params))
  return id
})

export const kidsSlice = createSlice({
  name: 'kids',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    parents: [],
    loading: false,
    kids: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getParents.fulfilled, (state, action) => {
        state.parents = action.payload.parents
      })
      .addCase(getKids.fulfilled, (state, action) => {
        state.kids = action.payload.kids
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default kidsSlice.reducer
